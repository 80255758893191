import React, { createContext, useState, useRef, useContext, useEffect } from 'react';

const AudioContext = createContext();

export const useAudio = () => {
  return useContext(AudioContext);
};

export const AudioProvider = ({ children }) => {
  const [currentAudio, setCurrentAudio] = useState(null);
  const isPlayingRef = useRef(false);
  const audioRef = useRef(null);

  const playSound = async (audioUrl) => {

    if (audioRef.current && audioRef.current.src === audioUrl) {
      
      if (isPlayingRef.current) {
        audioRef.current.pause();
        isPlayingRef.current = false;
      } else {
        audioRef.current.currentTime = 0; // Reset the current time to the start
        audioRef.current.play();
        isPlayingRef.current = true;
      }
      return;
    }

    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.removeEventListener('ended', handleAudioEnded);
    }

    const audio = new Audio(audioUrl);
    audioRef.current = audio;

    audio.addEventListener('ended', handleAudioEnded);

    audio.play();
    setCurrentAudio(audioUrl);
    isPlayingRef.current = true;
  };

  const handleAudioEnded = () => {
    isPlayingRef.current = false;
  };

  const stopSound = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.removeEventListener('ended', handleAudioEnded);
      audioRef.current = null;
    }
    setCurrentAudio(null);
    isPlayingRef.current = false;
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener('ended', handleAudioEnded);
      }
    };
  }, []);

  const value = {
    currentAudio,
    isPlayingRef,
    playSound,
    stopSound,
  };

  return (
    <AudioContext.Provider value={value}>
      {children}
    </AudioContext.Provider>
  );
};
