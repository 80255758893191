import logo from './logo.svg';
import './App.css';
import Body from './components/body';

function App() {
  return (
    <div className="App">

      <div className='App-content'>
      <Body />

      </div>




     
    </div>
  );
}

export default App;
