import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { db, doc, getDoc, getDocs, collection, storage, getStorage, ref, getDownloadURL,query, where } from '../models/firebase';
import { useAudio } from '../context/AudioContext';
import AllergyModel from '../models/AllergyModel'
import LanguageModel from '../models/LanguageModel';
import SelectLanguage from './selectLanguage';
import NoQrView from './noqrView';
import LoadingView from './loadingView';
import { getLanguage } from '../models/FirestoreFunctions';
import './body.css'
import { snapshotEqual } from 'firebase/firestore';

function Body() {
  //const userId = "51pJ58i4RkR7OvM7tsSgLC4HqM63";
  //const qrCodeId ="PovkwpMmTQY885JyuA1O";
  let qrCodeId ="";
  const [userLanguageBrowser, setUserLanguage] = useState();
  const [allergiesList, setAllergies] = useState([]);
  const [languageTranslation, setLanguageTranslated] = useState();
  const [loading, setLoading] = useState(true);
  const[showPage, setShowPage] = useState();
  const [audioMain, setAudioMain] = useState('');
 
  const [iconAssets, setIconAssets] = useState({});
  const [showModalSelectLanguage, setShowModalLanguage] = useState(false);
  const [languageList, setLanguageList] = useState([]);


  const {playSound, stopSound, isPlayingRef } = useAudio();





const getAssets = async () => {

   // Fetch the image URL for an example allergy (e.g., 'celery')

   const storage = getStorage();
   const icons = {
    speaker: ref(storage, 'icon/speaker.png'),
    warning: ref(storage, 'icon/warning.png'),
    tableSet: ref(storage, 'icon/tableSet.png')
  };

  const assets = {};
  for (const key in icons) {
    if (icons.hasOwnProperty(key)) {
      try {
        const url = await getDownloadURL(icons[key]);
        assets[key] = url;
      } catch (error) {
        console.error(`Error fetching ${key} icon URL:`, error);
      }
    }
  }
  setIconAssets(assets);

}


  useEffect(() => {

    //const queryParams = new URLSearchParams(location.search);
    //const userId = queryParams.get('qrId'); // Assuming the parameter is named 'userId'
    //console.log('qrId from query:', userId);
    

    const fetchQueryUrl = () => {

      const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    qrCodeId = urlParams.get('qrCode')
    console.log("QR---",qrCodeId);

    }
    


    const fetchLanguages = async () => {
      const languages = await getLanguage();
      setLanguageList(languages);
     
    };

 


    const fetchUserData = async () => {

      
          //--1--load language based on user browser
          const browserLanguage = navigator.language || navigator.userLanguage;
          const userLanguage = browserLanguage.substring(0,2)
          setUserLanguage(userLanguage)
          //console.log('Browser Language:', userLanguage);
          //console.log('Browser LanguageUseState:', userLanguageBrowser);

           fetchQueryUrl()

      try {
        


        const qrDocRef = doc(db, 'qrCodes', qrCodeId);
        const qrDoc = await getDoc(qrDocRef);

        if (qrDoc.exists()) {

          fetchLanguages();

          //Search the corresponding qr code Id
          const allergyListRef = collection(db, 'qrCodes', qrCodeId, 'allergiesList');
          const allergyListSnap = await getDocs(allergyListRef);
          const allergyData = allergyListSnap.docs.map(doc => doc.data());

          //console.log('Allergy-Data--ARRAY--',allergyData)
                 
          
          const allergyListSnapPromises = allergyData.map(async (allergy) => { 

            //With the id of this first array of qr code, we will search more data in the collection of allergy
            const allergyId = parseInt(allergy['id'])
            const allergyRef = collection(db, 'allergies');
        
            //Crate the query to find more detail by element
            const q = query(allergyRef, where("id", "==", allergyId));
            const querySnapshotAllergy = await getDocs(q);
            const queryData = querySnapshotAllergy.docs.map(doc => doc.data());
            const allergyData = queryData[0]
         
         
            const allergyName = allergyData['name'];
            const allergyTranslation = allergyData['translation'];
            const allergyImageRef = ref(storage, 'allergy/'+allergyName.toLowerCase()+'.png');
            const allergyImageUrl = await getDownloadURL(allergyImageRef);

            const browserLanguage = navigator.language || navigator.userLanguage;
            const userLanguage = browserLanguage.substring(0,2)
            
            const allergyAudioRef = ref(storage, 'audio_allergens/'+userLanguage+'/'+userLanguage+'_'+allergyName+'.mp3');
            const allergyAudioUrl = await getDownloadURL(allergyAudioRef)
        

            try {
            
              const allergyToAdd = new AllergyModel(
                allergyId, 
                allergyName,
                allergyImageUrl,
                allergyTranslation,
                allergyAudioUrl
                );
              return allergyToAdd;
            } catch (error) {
              console.error('Error fetching image URL:', error);
              return null; // Handle the error case by returning null or a placeholder object
            }
   


          });

          const finalListAllergies = await Promise.all(allergyListSnapPromises);
          //console.log("finalList-------",finalListAllergies)
          
          setAllergies(finalListAllergies);

          const newAllergies = [];
          
          for (let allergy of allergyData) {
            
            
   
            const allergyId = allergy['id']

            const allergyName = allergy['name']
            
            const allergyImageRef = ref(storage, 'allergy/'+allergyName.toLowerCase()+'.png');
            const allergyImageUrl = await getDownloadURL(allergyImageRef);

            const browserLanguage = navigator.language || navigator.userLanguage;
            const userLanguage = browserLanguage.substring(0,2)
            
            const allergyAudioRef = ref(storage, 'audio_allergens/'+userLanguage+'/'+userLanguage+'_'+allergyName+'.mp3');
            const allergyAudioUrl = await getDownloadURL(allergyAudioRef)
            
            const newData = new AllergyModel(
              allergyId, 
              allergyName,
              allergyImageUrl,
              "",
              allergyAudioUrl
              );
            console.log("newToAdd--", newData);
            newAllergies.push(newData);
           
         

          }
        
      
          const soundMainRef = ref(storage, 'audio_main/' + userLanguage + '-main.wav');
          const soundMainUrl = await getDownloadURL(soundMainRef);
          setAudioMain(soundMainUrl);
           
           
           
           await getAssets();

         

          const languageRef = collection(db,"languages");
        
          //Query to get the language
          const q = query(languageRef, where("language", "==", userLanguage));
          const querySnapshotLanguage = await getDocs(q);
          const queryData = querySnapshotLanguage.docs.map(doc => doc.data());
          const languageData = queryData[0]
          const languageImageRef = ref(storage, 'flags/'+userLanguage.toLowerCase()+'.png');
          const languageImageUrl = await getDownloadURL(languageImageRef);
    

          const languageToUse = new LanguageModel(
            languageData['id'],
            languageData['language'],
            languageData['name'],
            languageData['languageTranslation'],
            languageImageUrl,
            languageData['translationCardTitle'],
            languageData['translation'],
           

          )
          //console.log("languageToUse--------------",languageToUse)
          //console.log(languageData["translation"])
          setLanguageTranslated(languageToUse)
          setShowPage(true)
             //----end--load language based on user browser

        } else {
          setShowPage(false)
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setShowPage(false);

      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handlePlaySound = () => {
    playSound(audioMain);
  };

  const handlePlayAllergens = async (allergySelected) => {
    console.log(allergySelected);
    playSound(allergySelected.audioUrl);
 
  };


  const openModalLanguage = () => {
    setShowModalLanguage(true);
  };

  const closeModalLanguage = () => {
    setShowModalLanguage(false);
  };

  const fetchAudioUrl = async (languageCode) => {
    try {
      // 1. Update the main text URL translation
      const soundMainRef = ref(storage, 'audio_main/' + languageCode + '-main.wav');
      const soundMainUrl = await getDownloadURL(soundMainRef);
      setAudioMain(soundMainUrl);
  
      // 2. Update all allergens URL text translation
      const allergyPromises = allergiesList.map(async (allergy) => {
        const allergyAudioRef = ref(storage, 'audio_allergens/' + languageCode + '/' + languageCode + '_' + allergy.name + '.mp3');
        const allergyAudioUrl = await getDownloadURL(allergyAudioRef);
        return {
          ...allergy,
          audioUrl: allergyAudioUrl,
        };
      });
  
      const updatedAllergiesList = await Promise.all(allergyPromises);
      setAllergies(updatedAllergiesList);
  
    } catch (error) {
      console.error('Error fetching audio URL:', error);
      // Handle the error if necessary, e.g., set a fallback audio URL
    }
  };
  
  

  const handleSelectLanguage = async (languageSelected) => {
    //console.log(languageSelected);
    setUserLanguage(languageSelected.language);
    setLanguageTranslated(languageSelected);
    await fetchAudioUrl(languageSelected.language);
    closeModalLanguage();
  };



  if (loading) {

    return (
      <LoadingView/>
    )
    
    
 
  }

  if (showPage === false) {

    return (
      <NoQrView/>
    )
    

  }

  if (showPage) {

    return (
      <div className='page-content'>
  
  
        
        <div className="header-container">
         {iconAssets.tableSet && <img src={iconAssets.tableSet} alt="Table Set Icon" className="icon-style"/>}
  
          <p>{languageTranslation.translationCardTitle}</p>
        
          {iconAssets.tableSet && <img src={iconAssets.warning} alt="Table Set Icon" className="icon-style"/>}
  
        </div>
  
      <div className='body-container'>
      
      <div className="allergy-list">
             <div className='allergy_list_containter'>
  
  
    
          {allergiesList.map((item, index) => (
              <div className='allergy_list_content'
              key={index} 
              onClick={() => handlePlayAllergens(item)}  
  
              >
                <img 
                  className='allergy_img'
                src={item.imgUrl} alt={item.name}/>
                <p className='allergy_title'>{item.translation[userLanguageBrowser]}</p>
                {userLanguageBrowser !== "en" ? (
     <p className='allergy_title_english'>{item.name}</p>
  ): ("")}
             
  
             <button 
      
          className='button-style-small'
          >
          {iconAssets.speaker && <img className="button_action-small" src={iconAssets.speaker} alt="Speaker Icon" />}
       
          </button>
  
              </div>
            ))}
      </div>
  
  
        </div>
  
  
        <div className='main_translation-content'>
            <p className='main_translation-text'>{languageTranslation.translation}</p>
  
            <button 
          onClick={handlePlaySound}
          className='button-style'
          >
          {iconAssets.speaker && <img className="button_action" src={iconAssets.speaker} alt="Speaker Icon" />}
       
          </button>
  
        </div>
  
      
        <div className="button_bottom_container">
  
     
  
          <div className="flag-containe">
  
  
            <button
             className='button-style'
              onClick={openModalLanguage}
            >
              <img className="icon-flag-top" 
  src={languageTranslation.imgUrl}
  />
  
  
  
      {languageTranslation.languageTranslation[userLanguageBrowser] ? (
        <span>{languageTranslation.languageTranslation[userLanguageBrowser]}</span>
        ) : (
        <span>{languageTranslation.languageTranslation.name}</span>
          )}
            </button>
  
  
  
  </div>
  
  
        </div>
  
        {showModalSelectLanguage && (
          <SelectLanguage
            title="Select Language Modal"
            onClose={closeModalLanguage}
            onSelectLanguage={handleSelectLanguage}
            languages={languageList}
          />
        )}
  
      </div>
  
  
       
  
       
  
  
  
  
      </div>
    );

  }






 
 



}

export default Body;
