import { db, getDocs, collection, storage, ref, getDownloadURL } from './firebase';
import LanguageModel from './LanguageModel';

const getLanguage = async () => {
  let languageList = [];

  try {
    const languageRef = collection(db, "languages");
    const languageListSnap = await getDocs(languageRef);
    const languageListQuery = languageListSnap.docs.map(doc => doc.data());

    const languagePromises = languageListQuery.map(async (language) => {
      const languageTwoDigits = language['language'];
      const imageRef = ref(storage, 'flags/' + languageTwoDigits.toLowerCase() + '.png');

      try {
        const fullImageUrl = await getDownloadURL(imageRef);
        const languageToAdd = new LanguageModel(
          language['id'],
          language['language'],
          language['name'],
          language['languageTranslation'],
          fullImageUrl,
          language['translationCardTitle'],
          language['translation'],
        );
        return languageToAdd;
      } catch (error) {
        console.error('Error fetching image URL:', error);
        return null; // Handle the error case by returning null or a placeholder object
      }
    });

    languageList = await Promise.all(languagePromises);
    languageList = languageList.filter(language => language !== null); // Filter out any null values
    languageList = languageList.sort((a, b) => a.name.localeCompare(b.name));
    return languageList;

  } catch (error) {
    console.error('Error fetching user data:', error);
    return []; // Return an empty array ia case of an error
  }
}

export { getLanguage };
