import React from 'react';
import './noqrView.css';


function NoQrView() {

    return(
        <div>
            <p>QR Code not found or invalid</p>

        </div>
    )


}


export default NoQrView;