
class AllergyModel {
    constructor(id, name, imgUrl, translation,audioUrl) {
      this.id = id
      this.name = name;
      this.imgUrl = imgUrl;
      this.translation = translation
      this.audioUrl = audioUrl
    }
  }

  export default AllergyModel