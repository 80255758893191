
class LanguageModel {
    constructor(id, language, name, languageTranslation, imgUrl, translationCardTitle, translation) {
        this.id = id;
        this.language = language;
        this.name = name;
        this.languageTranslation = languageTranslation;
        this.imgUrl = imgUrl;
        this.translationCardTitle = translationCardTitle;
        this.translation = translation;
        //audioAvailable:boolean
    }
 
}

export default LanguageModel