import React from 'react';
import './selectLanguage.css';

const SelectLanguage = ({ title, onClose, onSelectLanguage, languages }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        <div className="language-list">
          {languages.map((language, index) => (
            <div key={index} className="item_container" onClick={() => onSelectLanguage(language)}>
              <div className="item_language">
                <img className='img_style' src={language.imgUrl} alt={language.name} />
                <div className="item_left">
                  <span className="item_text">{language.name}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="button-close" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default SelectLanguage;
