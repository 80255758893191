import React from "react";
import './loadingView.css';


function LoadingView(){

    return(
        <div className='main-content'>
            <p>Loading</p>
        </div>

    )
}

export default LoadingView